import styled from "styled-components";
import { ReactComponent as ReviewsWave } from "./reviews-wave.svg";

const BottomReviewsWave = styled(ReviewsWave)`
  transform: scale(-1) translateY(-5px);

  ${({theme}) => theme.media.min.laptop`
    margin-top: -150px;
    transform: scaleX(-1) scaleY(-0.5) translateY(-100%);
  `}
`;

export default BottomReviewsWave;
