import React from "react";
import styled from "styled-components";
import { StyledProps } from "types/StyledProps";

const SuccessMessage: React.FC<StyledProps> = (props: StyledProps) => {
  return (
    <p id="contact-success" className={props.className}>
      Formularz został przesłany pomyślnie. <br />
      Dziękujemy!
    </p>
  );
};

const StyledSuccessMessage = styled(SuccessMessage)`
  width: 100%;
  padding: 24px;
  color: ${({ theme }) => theme.colors.light};
  background: ${({ theme }) => theme.gradients.main(135)};
  border-radius: 16px;
  line-height: 2;
  animation: slide-in-origin 1s both;

  ${({ theme }) => theme.media.min.laptop`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    height: 100%;
    margin: auto;
    z-index: 3;
    font-size: 1.5rem;
  `}
`;

export default StyledSuccessMessage;
