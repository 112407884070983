import styled from "styled-components";

const SocialLinks = styled.div`
  display: flex;

  svg {
    height: 24px;
    margin: 16px;
    transition: transform 0.25s;

    :hover {
      transform: scale(1.25);
    }
  }
`;

export default SocialLinks;
