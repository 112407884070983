import styled from "styled-components";
import { ReactComponent as DotsGrid } from "assets/svg/dots-grid.svg";

const FirstCardDots = styled(DotsGrid)`
  position: absolute;
  right: -15px;
  top: -35px;
  width: 150px;
  z-index: -1;

  ${({ theme }) => theme.media.min.laptop`
    right: revert;
    top: revert;
    left: -75px;
    bottom: -75px;
    width: 200px;
  `}
`;

export default FirstCardDots;
