import styled from "styled-components";
import { ReactComponent as CornerStroke } from "assets/svg/corner-stroke.svg";

const LowerCornerStroke = styled(CornerStroke)`
  position: absolute;
  bottom: 0;
  right: 0px;
  width: 75px;
  transform: translate(50%, 50%) rotate(180deg);
  z-index: -1;
`;

export default LowerCornerStroke;
