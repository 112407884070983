import styled from "styled-components";

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.media.min.desktop`
    flex-direction: row-reverse;
    max-width: 1200px;
    margin: 0 auto;
    justify-content: center;
  `}
`;

export default CardWrapper;
