import styled from "styled-components";
import Card from "components/Card";

const AboutCard = styled(Card)`
  ${({ theme }) => theme.media.min.laptop`
    width: 100%;
    height: 375px;
  `}
`;

export default AboutCard;
