import { Link } from "gatsby";
import styled, { css } from "styled-components";
import { StyledProps } from "types/StyledProps";

interface LinkProps extends StyledProps {
  $underlined?: boolean;
  $isDocked?: boolean;
}

const underlinedLink = css<LinkProps>`
  content: "";
  display: block;
  position: absolute;
  width: 15px;
  height: 3px;
  background: ${({ theme, $isDocked }) => $isDocked ? theme.colors.light : theme.colors.mainMid};
  top: 150%;
  left: 50%;
  transform: translateX(-50%) scaleX(1);
  border-radius: 100vw;
  opacity: 1;
  visibility: visible;
  transition: transform 0.25s, opacity 0.25s, visibility 0.25s;
`;

const StyledLink = css<LinkProps>`
  position: relative;
  text-decoration: none;
  color: ${({ theme, $isDocked }) => $isDocked ? theme.colors.light : theme.colors.mainMid};
  font-weight: ${({ theme }) => theme.fontWeights.medium};

  :after {
    ${underlinedLink}
    opacity: ${({ $underlined }) => ($underlined ? 1 : 0)};
    visibility: ${({ $underlined }) => ($underlined ? "visible" : "hidden")};
    transform: ${({ $underlined }) =>
      $underlined ? "translateX(-50%) scaleX(1)" : "translateX(-50%) scaleX(0)"};
  }

  :hover:after,
  :focus:after,
  :active:after {
    ${underlinedLink}
  }
`;

const StyledLocalLink = styled.a<LinkProps>`
  ${StyledLink}
`;

const StyledGatsbyLink = styled(Link)<LinkProps>`
  ${StyledLink}
`;

const StyledButtonLink = styled.a<LinkProps>`
  text-decoration: none;
  color: ${({ theme, $underlined, $isDocked }) => $underlined || $isDocked ? theme.colors.light : theme.colors.mainMid};
  background-color: ${({ theme, $underlined }) => $underlined ? theme.colors.mainMid : "transparent"};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  padding: 0.75em 3em;
  border: 0.125em solid ${({ theme, $isDocked }) => $isDocked ? theme.colors.light : theme.colors.mainMid};
  border-radius: 100vw;
  transition: background-color 0.25s, color 0.25s;

  :hover {
    background-color: ${({ theme, $isDocked }) => $isDocked ? theme.colors.light : theme.colors.mainMid};
    color: ${({ theme, $isDocked }) => $isDocked ? theme.colors.mainMid : theme.colors.light};
  }
`;

export { StyledLocalLink, StyledGatsbyLink, StyledButtonLink };
