import styled from "styled-components";
import { ReactComponent as HeartIcon } from "assets/svg/icons/heart.svg";

const Heart = styled(HeartIcon)`
  position: absolute;
  left: -25px;
  bottom: -20px;
  width: 50px;
  z-index: -1;
  transform: rotate(-15deg);
`;

export default Heart;
