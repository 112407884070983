import styled from "styled-components";
import { ReactComponent as CornerStroke } from "assets/svg/corner-stroke.svg";

const UpperCornerStroke = styled(CornerStroke)`
  position: absolute;
  top: 0;
  left: 0;
  width: 75px;
  transform: translate(-50%, -50%);
  z-index: -1;
`;

export default UpperCornerStroke;
