import styled from "styled-components";

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
  height: 100%;
  max-width: ${({ theme }) => theme.maxWidth}px;
  margin: 0 auto;
  padding: 100px 25px;
  padding-bottom: 15%;
  color: ${({ theme }) => theme.colors.light};

  ${({ theme }) => theme.media.min.laptop`
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
    padding: 25vh 40px;
  `}
`;

export default ContentWrapper;