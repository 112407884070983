import styled from "styled-components";
import { ReactComponent as DotsGrid } from "assets/svg/dots-grid.svg";

const MiddleCardDots = styled(DotsGrid)`
  position: absolute;
  right: -35px;
  top: -35px;
  width: 150px;
  z-index: -1;
`;

export default MiddleCardDots;
