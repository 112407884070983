import React from "react";
import styled, { css } from "styled-components";
import Header from "components/Header";
import FirstCard from "./FirstCard";
import MiddleCard from "./MiddleCard";
import LastCard from "./LastCard";
import AboutArrow from "./visuals/AboutArrow";
import AboutParagrapgh from "./AboutParagraph";
import CardWrapper from "./CardWrapper";
import Reveal from "components/Reveal";
import { StyledProps } from "types/StyledProps";

const AboutSection: React.FC<StyledProps> = (props: StyledProps) => {
  return (
    <section css={animations} id="about" className={props.className}>
      <div style={{ position: "relative" }}>
        <Reveal animationClass="header">
          <Header colored>Czym się zajmujemy?</Header>
        </Reveal>
        <Reveal animationClass="paragraph">
          <AboutParagrapgh colored>
            Specjaliści w naszej agencji zatroszczą się o wizerunek Twojego
            gabinetu. Dzięki reklamie Twoja marka zyska na popularności, a
            pozostałe usługi zapewnią jej wrażenie profesjonalizmu i elegancji.
          </AboutParagrapgh>
        </Reveal>
        <Reveal animationClass="arrow" offset={20}>
          <AboutArrow />
        </Reveal>
      </div>
      <CardWrapper>
        <FirstCard />
        <MiddleCard />
        <LastCard />
      </CardWrapper>
    </section>
  );
};

const StyledAboutSection = styled(AboutSection)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;

  ${({ theme }) => theme.media.min.laptop`
    scroll-margin-top: 250px;
  `}

  ${({ theme }) => theme.media.min.largeDesktop`
    flex-direction: row-reverse;
    max-width: ${theme.maxWidth}px;
    margin: 0 auto;
    transform: translateX(-75px);
  `}
`;

const animations = css`
  .header, .paragraph {
    animation: slide-in-bottom 0.85s both;
  }

  .paragraph {
    animation-delay: 0.25s;
  }

  .arrow {
    ${({theme}) => theme.media.min.largeDesktop`
      animation: arrow 0.85s both;
      animation-delay: 0.25s;
    `}
  }

  @keyframes arrow {
    0% {
      opacity: 0;
      transform: rotate(-180deg);
    }
    35% {
      opacity: 0;
    }
    100% {
      opacity: 1;
      transform: rotate(0deg);
    }
  }
`;

export default StyledAboutSection;
