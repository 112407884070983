import React, { useContext, useEffect, useState } from "react";
import styled, { withTheme } from "styled-components";

import { useScrollPosition } from "utils/useScrollPosition";
import { TopMenuContext } from "contexts/TopMenuContext";

import Navigation from "./Navigation";

import { ReactComponent as Logo } from "assets/svg/logo.svg";
import MenuIcon from "./MenuIcon";
import { StyledProps } from "types/StyledProps";

interface NavBarProps extends StyledProps {
  isExpanded: boolean;
  isDocked: boolean;
  scrolledDown: boolean;
  isLoaded: boolean;

  handleLinkClick: () => void;
  handleMenuIconClick: () => void;
}

const StyledLogo = styled(Logo)<{ $isDocked: boolean }>`
  margin: 16px;
  fill: ${({ theme, $isDocked }) =>
    $isDocked ? theme.colors.light : theme.colors.mainMid};
`;

const NavBarWrappper: React.FC<StyledProps> = (props: StyledProps) => {
  const {
    isExpanded,
    isDocked,
    setIsDocked,
    setIsExpanded,
    scrolledDown,
    setScrolledDown,
  } = useContext(TopMenuContext);

  const setDockedIfNecessary = () => {
    if (window.scrollY === 0 && !isExpanded) {
      setIsDocked(true);
    }
  }

  useEffect(() => {
    setTimeout(() => setIsLoaded(true), 250);
    setDockedIfNecessary();
  }, []);

  const [isLoaded, setIsLoaded] = useState(false);

  function handleLinkClick(): void {
    // Close the menu after clicking background shadow and navigating
    if (isExpanded && window.innerWidth <= 1024) {
      setIsExpanded(false);
    }

    setTimeout(() => {
      setDockedIfNecessary();
    });
  }

  function handleMenuIconClick(): void {
    setIsExpanded(!isExpanded);

    // Docking should appear only on the main page
    if (window.location.pathname !== "/") {
      return;
    }

    // If on the top of the page, dock the menu after closing it
    setTimeout(() => setIsDocked(isExpanded && window.scrollY === 0));
  }

  useScrollPosition(({ prevPos, currPos }) => {
    // If the position hasn't changed, return
    if (prevPos === currPos) {
      return;
    }

    // If we have scrolled down and the menu isn't expanded, hide menu
    setScrolledDown(prevPos.y >= currPos.y && !isExpanded);

    // If we are on the top of the page and menu isn't expanded, dock menu
    setIsDocked(currPos.y === 0 && !isExpanded);
  });

  return (
    <StyledNavBar
      isDocked={isDocked}
      isLoaded={isLoaded}
      isExpanded={isExpanded}
      handleLinkClick={handleLinkClick}
      handleMenuIconClick={handleMenuIconClick}
      scrolledDown={scrolledDown}
    />
  );
};

const NavBar: React.FC<NavBarProps> = (props: NavBarProps) => {
  return (
    <header className={props.className}>
      <nav>
        <div className="topmenu-logo">
          <StyledLogo $isDocked={props.isDocked} />
        </div>
        <Navigation
          isExpanded={props.isExpanded}
          isDocked={props.isDocked}
          onClick={props.handleLinkClick}
        />
        <MenuIcon
          isDocked={props.isDocked}
          isExpanded={props.isExpanded}
          onClick={props.handleMenuIconClick}
        />
      </nav>
    </header>
  );
};

const StyledNavBar = withTheme(styled(NavBar)`
  width: 100%;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  transition: transform 0.5s;
  background-color: transparent;

  transform: ${({ isExpanded, scrolledDown }) =>
    scrolledDown && !isExpanded && "translateY(-125%)"};

  ::after {
    content: "";
    visibility: ${({ isDocked, isLoaded }) => (!isLoaded || isDocked ? "hidden" : "visible")};
    opacity: ${({ isDocked }) => (isDocked ? 0 : 1)};
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    box-shadow: 0 3px 5px 0px rgba(0, 0, 0, 0.2);
    z-index: -1;
    background-color: ${({ theme }) => theme.colors.light};
    transition: visibility 0.5s, opacity 0.5s;
  }

  nav {
    margin: 0 auto;
    max-width: ${({ theme }) => theme.maxWidth}px;
    display: flex;
    align-items: center;
    justify-content: start;
    padding: 12px;

    ${({theme}) => theme.media.min.laptop`
      padding: 25px;
    `}
  }

  svg {
    width: 150px;
    margin: 0;
  }
`);

export default NavBarWrappper;
