import styled from "styled-components";
import gradientFonts from "./gradientFonts";

const StatsCount = styled.h3`
  ${gradientFonts}

  position: relative;
  left: 0.5rem;
  font-size: 2rem;

  ${({ theme }) => theme.media.min.desktop`
    font-size: 2.5rem;
  `}
`;

export default StatsCount;
