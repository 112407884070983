import React from "react";
import styled, { css } from "styled-components";
import Paragraph from "components/Paragraph";
import Reveal from "components/Reveal";
import { StyledProps } from "types/StyledProps";

const WeWillReply = styled(Paragraph)`
  text-align: center;
  font-size: 1.5rem;

  ${({ theme }) => theme.media.min.laptop`
    font-size: 2rem;
  `}
`;

const StyledParagraph = styled(Paragraph)`
  margin: 16px auto;
  margin-bottom: 36px;
`;

const Description: React.FC<StyledProps> = (props: StyledProps) => {
  return (
    <div css={animations} className={props.className}>
      <Reveal offset={40} mobileOffset={60} animationClass="description">
        <div>
          <StyledParagraph colored>
            Zostaw nam krótką wiadomość z pytaniem lub propozycją współpracy.
          </StyledParagraph>
          <WeWillReply colored>Odpowiemy.</WeWillReply>
          <StyledParagraph colored>
            Podane przez Ciebie dane kontaktowe posłużą nam jedynie do
            odpowiedzi na Twoją wiadomość.
          </StyledParagraph>
          <StyledParagraph colored>
            Obiecujemy nie przekazywać żadnych Twoich danych osobom trzecim.
            Twoje bezpieczeństwo jest dla nas bardzo ważne.
          </StyledParagraph>
        </div>
      </Reveal>
    </div>
  );
};

const StyledDescription = styled(Description)`
  grid-area: description;
`;

const animations = css`
  .description {
    opacity: 1 !important;
  }

  .description p {
    animation: slide-in-bottom 0.85s both;

    :nth-of-type(2) {
      animation-delay: 0.25s;
    }

    :nth-of-type(3) {
      animation-delay: 0.5s;
    }

    :nth-of-type(4) {
      animation-delay: 0.75s;
    }
  }
`;

export default StyledDescription;
