import styled from "styled-components";

const Label = styled.label`
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 1rem;
  z-index: 1;
  cursor: text;
  user-select: none;
  color: ${({ theme }) => theme.colors.grayMid};
  transition: transform 0.25s, top 0.25s;
`;

export default Label;
