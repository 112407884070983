import styled from "styled-components";

const ReviewImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  width: 30%;
  padding: 5px;
  border-radius: 100vw;
  transform: translateY(-50%);
  background: ${({ theme }) => theme.colors.light};

  ${({ theme }) => theme.media.min.mobile`
    width: 115px;
  `}

  ${({ theme }) => theme.media.min.tablet`
    right: 30px;
    width: 115px;
    transform: translateY(-25%);
  `}
`;

export default ReviewImageWrapper;
