import styled from "styled-components";

const Header = styled.div<{ colored?: boolean; large?: boolean, centered?: boolean }>`
  margin: 0;
  text-align: center;
  font-size: ${({ large }) =>
    large ? "clamp(1.5rem, 5vw, 3rem)" : "clamp(1rem, 5vw, 1.5rem)"};
  font-weight: ${({ theme, large }) =>
    large ? theme.fontWeights.medium : theme.fontWeights.semiBold};

  color: ${({ theme, colored }) =>
    colored ? theme.colors.mainMid : theme.colors.light};

  ${({ theme, colored }) =>
    colored &&
    `
    background: -webkit-linear-gradient(${theme.colors.mainLight}, ${theme.colors.mainDark});
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  `}

  ${({ theme, centered }) => !centered && theme.media.min.laptop`
    text-align: left;
  `}

  em {
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    font-style: normal;
  }

  ::before {
    display: block;
    content: "";
    width: 30px;
    height: 4px;
    margin: 0 auto;
    margin-bottom: 12px;
    background-color: ${({ theme, colored }) =>
      colored ? theme.colors.mainMid : theme.colors.light};

    ${({ theme, centered }) => !centered && theme.media.min.laptop`
      height: 5px;
      margin: 0;
      margin-bottom: 4px;
      border-radius: 100vw;
    `}
  }
`;

export default Header;
