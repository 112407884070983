import styled from "styled-components";

const ReferralLink = styled.a`
  all: unset;
  display: inline-block;
  margin: 16px;
  padding: 8px 16px;
  border-radius: 100vw;
  background: ${({ theme }) => theme.colors.light};
  color: ${({ theme }) => theme.colors.mainMid};
  cursor: pointer;
  transition: transform 0.25s;

  :hover {
    transform: scale(1.1);
  }
`;

export default ReferralLink;
