import styled from "styled-components";
import { ReactComponent as RoundArrow } from "assets/svg/round-arrow.svg";

const AboutArrow = styled(RoundArrow)`
  position: absolute;
  top: 115%;
  right: 90%;
  width: 150px;
  opacity: 0;
`;

export default AboutArrow;