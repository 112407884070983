import styled from "styled-components";

const TextWrapper = styled.div`
  ${({ theme }) => theme.media.min.largeDesktop`
    display: inline-block;
    align-self: start;
    width: 33%;
    margin-top: 48px;
  `}
`;

export default TextWrapper;
