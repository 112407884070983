import styled from "styled-components";

const ChartLine = styled.div<{ height: number }>`
  flex: 1;
  height: ${({ height }) => height / 20}vw;
  margin: 0 3%;

  background: ${({ theme }) => theme.gradients.default};
  transform-origin: bottom;
`;

export default ChartLine;
