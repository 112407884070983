import styled from "styled-components";

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.media.min.laptop`
    flex-direction: row;
    margin: 0 auto;
    transform: scale(0.9) translateY(-25px);
    padding: 50px 150px;
  `}

  ${({ theme }) => theme.media.min.largeDesktop`
    width: 125%;
    margin-right: -25px;
    padding: 8px;
  `}
`;

export default CardWrapper;
