import styled from "styled-components";

const ReviewText = styled.p`
  margin: 24px 18px;
  font-size: 0.85rem;
  text-align: center;

  ${({ theme }) => theme.media.min.tablet`
    margin: 0;
    font-size: 1rem;
    text-align: left;
  `}
`;

export default ReviewText;
