import React from "react";
import styled, { css } from "styled-components";
import { StyledProps } from "types/StyledProps";
import ChartLine from "./ChartLine";
import Reveal from "components/Reveal";

const ChartLines: React.FC<StyledProps> = (props: StyledProps) => {
  return (
    <div css={animations} className={props.className}>
      <Reveal offset={25} animationClass="chart-line">
        <ChartLine height={290} />
        <ChartLine height={360} />
        <ChartLine height={330} />
        <ChartLine height={370} />
        <ChartLine height={420} />
        <ChartLine height={500} />
        <ChartLine height={600} />
      </Reveal>
    </div>
  );
};

const StyledChartLines = styled(ChartLines)`
  display: none;
  align-items: end;
  position: relative;
  width: 100%;
  height: 100vw;
  margin: 0 auto;
  padding: 0 10%;
  z-index: -2;
  clip-path: url(#clipping-reviews-wave_svg__reviews-wave-mask);

  ${({ theme }) => theme.media.min.desktop`
    display: flex;
    margin-top: -75vw;
    padding: 0 10%;
  `}

  ${({ theme }) => theme.media.min.largeDesktop`
    padding: 0 15%;
  `}
`;

const animations = css`
  .chart-line {
    opacity: 1;
    animation: chart-line 1s both;

    :nth-of-type(2) {
      animation-delay: 0.15s;
    }

    :nth-of-type(3) {
      animation-delay: 0.2s;
    }

    :nth-of-type(4) {
      animation-delay: 0.25s;
    }

    :nth-of-type(5) {
      animation-delay: 0.3s;
    }

    :nth-of-type(6) {
      animation-delay: 0.35s;
    }

    :nth-of-type(7) {
      animation-delay: 0.4s;
    }
  }

  @keyframes chart-line {
    0% {
      transform: scaleY(0);
    }

    60% {
      transform: scaleY(1.1);
    }

    100% {
      transform: scaleY(1);
    }
  }
`;

export default StyledChartLines;
