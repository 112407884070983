import React, { MutableRefObject, useState } from "react";
import styled from "styled-components";
import SliderArrow from "./SliderArrow";
import SwiperCore from "swiper";
import { StyledProps } from "types/StyledProps";

interface SliderArrowWrapperProps extends StyledProps {
  swiperRef: MutableRefObject<SwiperCore | null>;
  activeIndex: number;
}

const SliderArrowWrapper: React.FC<SliderArrowWrapperProps> = (
  props: SliderArrowWrapperProps
) => {
  const slide = (side: "left" | "right") => {
    if (!props.swiperRef?.current) {
      return;
    }

    side === "left"
      ? props.swiperRef.current.slidePrev(500)
      : props.swiperRef.current.slideNext(500);
  };

  return (
    <div className={props.className}>
      <SliderArrow
        disabled={props.activeIndex === 0}
        onClick={() => slide("left")}
        rotation="left"
      />
      <SliderArrow
        disabled={
          props.activeIndex ===
          (props.swiperRef.current?.slides?.length || 0) - 1 || props.activeIndex === -1
        }
        onClick={() => slide("right")}
        rotation="right"
      />
    </div>
  );
};

const StyledSliderArrowWrapper = styled(SliderArrowWrapper)`
  position: absolute;
  display: none;
  right: 50%;
  top: 100%;
  transform: translateX(50%);

  ${({theme}) => theme.media.min.laptop`
    display: block;
  `}

  ${({theme}) => theme.media.min.largeDesktop`
    right: 42%;
    transform: revert;
  `}
`;

export default StyledSliderArrowWrapper;
