import styled from "styled-components";
import HeroWaves from "assets/svg/hero-waves.svg";

const WavesWrapper = styled.div`
  position: relative;
  height: 100%;
  padding-bottom: 24px;
  background: url(${HeroWaves}) no-repeat;
  background-size: 200%;
  background-position-y: 100%;
  background-position-x: 40%;

  ${({ theme }) => theme.media.min.laptop`
    background-size: 100%;
    background-position-y: bottom;
    background-position-x: center;
  `}
`;

export default WavesWrapper;