import styled from "styled-components";
import { ReactComponent as HeroArrowSvg } from "assets/svg/hero-arrow.svg";

const HeroArrow = styled(HeroArrowSvg)`
  position: absolute;
  bottom: 5%;
  width: 70%;

  ${({ theme }) => theme.media.min.laptop`
    left: 0;
    bottom: 25%;
    width: 45%;
  `}
`;

export default HeroArrow;