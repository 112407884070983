import styled from "styled-components";

const ContentGrid = styled.div`
  display: grid;
  grid-template-areas: "description" "form" "illustration";
  margin-top: 10%;

  ${({ theme }) => theme.media.min.laptop`
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "form description" "form illustration" "form .";
    grid-gap: 64px;
    margin-top: 75px;
    padding: 48px;
  `}
`;

export default ContentGrid;
