import React, { ChangeEvent } from "react";
import styled from "styled-components";
import { StyledProps } from "types/StyledProps";
import { ReactComponent as DotsGrid } from "assets/svg/dots-grid.svg";
import InnerTextArea from "./InnerTextArea";
import Label from "./Label";
import Reveal from "components/Reveal";

interface TextAreaProps extends StyledProps {
  onChange: (e: ChangeEvent) => void;
}

const StyledDotsGrid = styled(DotsGrid)`
  position: absolute;
  bottom: -15px;
  right: -30px;
  width: 125px;
  z-index: -1;
`;

const TextArea: React.FC<TextAreaProps> = (props: TextAreaProps) => {
  return (
    <div className={props.className}>
      <InnerTextArea id="message" name="message" placeholder=" " onChange={props.onChange} />
      <Label htmlFor="message">Treść wiadomości</Label>
      <Reveal animationClass="dots-scale">
        <StyledDotsGrid />
      </Reveal>
    </div>
  );
};

const StyledTextArea = styled(TextArea)`
  position: relative;
  width: 100%;
  margin: 36px auto;
  border-radius: 12px;
  background: ${({ theme }) => theme.colors.light};
  box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.15);

  ::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    border-radius: 12px;
    opacity: 0;
    box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.2);
    transition: opacity 0.25s;
  }

  :hover::before,
  :focus-within::before {
    opacity: 1;
  }
`;

export default StyledTextArea;
