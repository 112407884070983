import styled from "styled-components";

const InnerTextArea = styled.textarea`
  display: block;
  position: relative;
  width: 100% !important;
  height: 250px;
  padding: 10px;
  font-size: 1rem;
  resize: none;
  border: none;
  border-bottom-style: solid;
  border-bottom-width: 12px;
  border-bottom-color: transparent;
  border-radius: 12px;
  color: ${({ theme }) => theme.colors.mainMid};
  transition: border-bottom-color 0.25s;

  :focus {
    outline: none;
    border-bottom-color: ${({ theme }) => theme.colors.mainMid};
  }

  :focus + label,
  &:not(:placeholder-shown) + label {
    top: -1.5rem;
    transform: translateX(-7%) scale(0.75);

    background: -webkit-linear-gradient(
      ${({ theme }) => `${theme.colors.mainLight}, ${theme.colors.mainDark}`}
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

export default InnerTextArea;
