import React from "react";
import styled from "styled-components";
import { StyledProps } from "types/StyledProps";
import { ReactComponent as PhoneIcon } from "assets/svg/icons/phone.svg";
import { ReactComponent as EmailIcon } from "assets/svg/icons/email.svg";

const Address = styled.li`
  grid-area: address;
`;

const Numbers = styled.li`
  grid-area: numbers;
`;

const Contact = styled.li`
  grid-area: contact;

  a {
    all: unset;
    cursor: pointer;
  }

  svg {
    display: none;
    position: relative;
    top: 3px;
    width: 16px;
    margin-right: 8px;

    :first-of-type path {
      stroke: ${({ theme }) => theme.colors.light};
    }

    :last-of-type path {
      fill: ${({ theme }) => theme.colors.light};
    }

    ${({ theme }) => theme.media.min.laptop`
      display: inline-block;
    `}
  }
`;

const Copyright = styled.li`
  grid-area: copyright;
`;

const CompanyData: React.FC<StyledProps> = (props: StyledProps) => {
  return (
    <ul className={props.className}>
      <Address>
        FollowUP Agencja Adrian Szerszeń <br /> Turza Mała 42c 06-545 Lipowiec
        Kościelny
      </Address>
      <Numbers>
        NIP 5691901641 <br /> REGON 520223223
      </Numbers>
      <Contact>
        <PhoneIcon />
        <a href="tel:795070502">tel. 795070502</a> <br />
        <EmailIcon />
        <a href="mailto:adrian@followup.social">e-mail: adrian@followup.social</a>
      </Contact>
      <Copyright>
        Copyright &copy; FollowUP Adrian Szerszeń 2021. <br /> Wszelkie prawa
        zastrzeżone.
      </Copyright>
    </ul>
  );
};

const StyledCompanyData = styled(CompanyData)`
  all: unset;
  display: grid;
  grid-template-areas: "address" "numbers" "contact" "copyright";
  margin: 0 auto;
  padding: 36px;
  text-align: center;
  list-style: none;
  color: ${({ theme }) => theme.colors.light};
  max-width: ${({ theme }) => theme.maxWidth}px;

  li {
    margin: 24px auto;
  }

  ${({ theme }) => theme.media.min.laptop`
    grid-template-columns: 2fr 3fr 1fr 2fr;
    grid-template-areas: "copyright address numbers contact";
    position: relative;
    left: 36px;
    text-align: left;
  `}
`;

export default StyledCompanyData;
