import React from "react";
import styled, { css } from "styled-components";
import Header from "components/Header";
import TextWrapper from "./TextWrapper";
import ReviewsParagraph from "./ReviewsParagraph";
import ContentWrapper from "./ContentWrapper";
import CardWrapper from "./CardWrapper";
import TopReviewsWave from "./visual/TopReviewsWave";
import BottomReviewsWave from "./visual/BottomReviewsWave";
import ReviewCard from "./ReviewCard/ReviewCard";
import { ReactComponent as StatsClippingReviewsWave } from "./visual/clipping-reviews-wave.svg";
import WiktoriaRozukLogo from "assets/images/wr-wiktoria-rozuk.jpg";
import LevelUpLogo from "assets/images/level-up.jpg";
import LipsAtelierLogo from "assets/images/lips-atelier.jpg";
import Reveal from "components/Reveal";
import { StyledProps } from "types/StyledProps";

const ReviewsSection: React.FC<StyledProps> = (props: StyledProps) => {
  return (
    <section css={animations} id="reviews" className={props.className}>
      <TopReviewsWave id="topReviewsWave" />
      <StatsClippingReviewsWave style={{ position: "absolute", zIndex: -1 }} />
      <ContentWrapper>
        <TextWrapper>
          <Reveal animationClass="header">
            <Header>Opinie klientów</Header>
          </Reveal>
          <Reveal offset={30} animationClass="paragraph">
            <ReviewsParagraph>
              Naszą rzetelność potwierdzają liczne opinie, w których klientki
              FollowUP dzielą się przebiegiem współpracy oraz efektami, jakie
              udało się osiągnąć.
            </ReviewsParagraph>
          </Reveal>
        </TextWrapper>
        <Reveal offset={325} mobileOffset={450} animationClass="reviews">
          <CardWrapper>
            <ReviewCard
              imgSrc={LipsAtelierLogo}
              stars={5}
              reviewer={"Lips Atelier"}
              review={
                "Zdecydowanie polecam współpracę z Adrianem. Ja swoją rozpoczęłam około 2 miesiące temu i nie żałuję! " +
                "Otrzymałam wiele informacji oraz pomocy zarówno w kwestii marketingu, jak i prowadzenia social mediów. " +
                "Adrian wytłumaczył mi mechanizmy dotyczące pozyskiwania klientów oraz ustawił chatbota, który odpisuje " +
                "zarówno potencjalnym, jak i moim stałym klientkom, gdy ja pracuję i nie używam telefonu. [...] Mam wiele " +
                "wiadomości - do tego stopnia, że nie nadążam odpisywać. Polecam każdemu, kto szuka " +
                "osoby do pomocy w kwestii marketingu i nie tylko!"
              }
            />
            <ReviewCard
              imgSrc={WiktoriaRozukLogo}
              stars={5}
              reviewer={"Wiktoria Rożuk\nKosmetologia"}
              review={
                "Serdecznie polecam współpracę z Adrianem, zaczęliśmy w tamtym miesiącu " +
                "i to była jedna z najlepszych decyzji, mimo że miałam do tego sceptyczne " +
                "nastawienie! [...] Adrian zawsze służy pomocą i rozwiąże wszystkie " +
                "twoje obawy lub pytania dotyczące współpracy! Przy współpracy z Adrianem rozpoznawalność " +
                "mojego salonu zdecydowanie wzrosła! 💓 Polecam Adriana wszystkim osobom, " +
                "które mają problem z prowadzeniem social mediów!"
              }
            />
            <ReviewCard
              imgSrc={LevelUpLogo}
              stars={5}
              reviewer={"Level Up Warszawa"}
              review={
                "Bardzo polecam współpracę z Adrianem, zaczęliśmy miesiąc temu i to była bardzo " +
                "dobra decyzja! Adrian wytłumaczył mi, jak wygląda pozyskiwanie klientów w social " +
                "media oraz ustawił chatbota, który odpowiada moim klientkom, kiedy ja pracuję. " +
                "Rozpoznawalność mojego profilu zdecydowanie wzrosła, a to dopiero początek naszej " +
                "współpracy :) Polecam Adriana każdemu, kto potrzebuje pomocy w prowadzeniu social mediów. 🙃💙"
              }
            />
          </CardWrapper>
        </Reveal>
      </ContentWrapper>
      <BottomReviewsWave id="bottomReviewsWave" />
    </section>
  );
};

const StyledReviewsSection = styled(ReviewsSection)`
  display: flex;
  flex-direction: column;
  position: relative;
  background: ${({ theme }) => theme.gradients.default};

  ${({ theme }) => theme.media.min.desktop`
    margin-top: -10vw;
  `}
`;

const animations = css`
  .header,
  .paragraph {
    animation: slide-in-bottom 0.85s both;
  }

  .paragraph {
    animation-delay: 0.25s;
  }

  .reviews {
    animation: slide-in-origin 0.85s both;
    animation-delay: 0.5s;
  }
`;

export default StyledReviewsSection;
