import React from "react";
import styled from "styled-components";
import Logo from "./Logo";
import LinkWrapper from "./LinkWrapper";
import SocialLinks from "./SocialLinks";
import Link, { StyledGatsbyLink } from "./Link";
import Links from "./Links";
import { StyledProps } from "types/StyledProps";
import { ReactComponent as FacebookIcon } from "assets/svg/icons/facebook.svg";
import { ReactComponent as InstagramIcon } from "assets/svg/icons/instagram.svg";

const Navigation: React.FC<StyledProps> = (props: StyledProps) => {
  return (
    <nav className={props.className}>
      <Logo />
      <Links>
        <LinkWrapper>
          <StyledGatsbyLink  to="/">Strona główna</StyledGatsbyLink>
        </LinkWrapper>
        <LinkWrapper>
          <Link href="#about">O nas</Link>
        </LinkWrapper>
        <LinkWrapper>
          <Link href="#reviews">Opinie</Link>
        </LinkWrapper>
        <LinkWrapper>
          <Link href="#contact">Kontakt</Link>
        </LinkWrapper>
      </Links>
      <SocialLinks>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.facebook.com/agencjafollowup"
        >
          <FacebookIcon />
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.instagram.com/adrian.szerszen"
        >
          <InstagramIcon />
        </a>
      </SocialLinks>
    </nav>
  );
};

const StyledNavigation = styled(Navigation)`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: ${({ theme }) => theme.maxWidth}px;
  margin: 0 auto;
  padding: 48px;

  ${({ theme }) => theme.media.min.laptop`
    flex-direction: row;
    justify-content: space-between;
  `}
`;

export default StyledNavigation;
