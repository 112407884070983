import styled from "styled-components";
import { ReactComponent as LikeIcon } from "assets/svg/icons/like.svg";

const Like = styled(LikeIcon)`
  position: absolute;
  top: -28px;
  right: -28px;
  width: 50px;
  z-index: -1;
  transform: rotate(-15deg);
`;

export default Like;
