import styled from "styled-components";

const IconWrapper = styled.div<{ iconStyle: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  width: 50px;
  padding: 14px;
  z-index: 5;

  path {
    ${({ theme, iconStyle }) => `${iconStyle}: ${theme.colors.grayMid}`};
  }

  ::after {
    content: "";
    display: block;
    position: absolute;
    right: 0;
    width: 1px;
    height: 50%;
    background: ${({ theme }) => theme.colors.grayMid};
  }
`;

export default IconWrapper;
