import React from "react";
import styled, { withTheme } from "styled-components";

import { StyledProps } from "types/StyledProps";
import {
  StyledButtonLink,
  StyledGatsbyLink,
  StyledLocalLink,
} from "./StyledLinks";

interface NavigationProps extends StyledProps {
  isDocked?: boolean;
  isExpanded?: boolean;

  onClick: () => void;
}

const Navigation: React.FC<NavigationProps> = (props) => {
  return (
    <div className={props.className}>
      <StyledShadow isExpanded={props.isExpanded} onClick={props.onClick}></StyledShadow>
      <ul>
        <li onClick={props.onClick}>
          <StyledGatsbyLink $isDocked={props.isDocked} to="/">
            Strona główna
          </StyledGatsbyLink>
        </li>
        <li onClick={props.onClick}>
          <StyledLocalLink $isDocked={props.isDocked} href="#about">
            O nas
          </StyledLocalLink>
        </li>
        <li onClick={props.onClick}>
          <StyledLocalLink $isDocked={props.isDocked} href="#reviews">
            Opinie
          </StyledLocalLink>
        </li>
        <li onClick={props.onClick}>
          <StyledButtonLink $isDocked={props.isDocked} href="#contact">
            Kontakt
          </StyledButtonLink>
        </li>
      </ul>
    </div>
  );
};

const StyledShadow = styled.div<NavigationProps>`
  transition: opacity 0.25s, visibility 0.25s;

  ${({theme, isExpanded}) => theme.media.max.desktop`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: -2;
    background-color: rgba(0, 0, 0, 0.25);
    opacity: ${isExpanded ? 1 : 0};
    visibility: ${isExpanded ? "visible" : "hidden"};
  `}
`;

const StyledNavigation = styled(Navigation)`
  width: 100%;

  ul {
    display: flex;
    justify-content: end;
    padding: 0;
    margin: 0;
    list-style: none;
    margin-left: 48px;
  }

  li {
    position: relative;
    margin: 0 36px;

    :last-of-type {
      margin-left: auto;
    }
  }

  ${({ theme, isExpanded }) => theme.media.below.laptop`
    ul {
      position: absolute;
      top: 0;
      left: 100%;
      flex-direction: column;
      justify-content: center;
      width: 80%;
      height: 100vh;
      padding-top: 10vh;
      margin-left: 0;
      z-index: -1;
      text-align: center;
      background-color: ${theme.colors.light};
      transition: transform .5s;
      transform: ${isExpanded ? `translateX(-100%)` : `translateX(0)`}};
    }
  `}

  ${({theme}) => theme.media.below.laptop`
    li {
      margin: 48px auto;
      font-size: 1.25rem;
    }
  `}
`;

export default withTheme(StyledNavigation);
