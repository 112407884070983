import styled from "styled-components";

const CardTitle = styled.h2`
  text-align: center;
  line-height: 1.2;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: clamp(1rem, 4.5vw, 2rem);

  color: ${({ theme }) => theme.colors.mainMid};
  background: -webkit-linear-gradient(
    ${({ theme }) => `${theme.colors.mainLight}, ${theme.colors.mainDark}`}
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  ${({theme}) => theme.media.min.laptop`
    margin: 20px auto;
    font-size: 1.5rem;
  `}
`;

export default CardTitle;
