import { css } from "styled-components";

const gradientFonts = css`
  margin: 0;
  text-align: center;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  line-height: 1.25;

  background: -webkit-linear-gradient(
    ${({ theme }) => `${theme.colors.mainLight}, ${theme.colors.mainDark}`}
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export default gradientFonts;
