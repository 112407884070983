import React, { useState, ChangeEvent, forwardRef } from "react";
import Input from "./Input";
import { StyledProps } from "types/StyledProps";
import { ReactComponent as PersonIcon } from "assets/svg/icons/person.svg";
import { ReactComponent as SalonIcon } from "assets/svg/icons/salon.svg";
import { ReactComponent as PhoneIcon } from "assets/svg/icons/phone.svg";
import { ReactComponent as EmailIcon } from "assets/svg/icons/email.svg";
import Button from "components/Button";
import TextArea from "./TextArea";
import styled from "styled-components";
import { NetlifyForm, Honeypot, Recaptcha } from "react-netlify-forms";
import Reveal from "components/Reveal";
import "../visual/contactForm.css";
import SuccessMessage from "../visual/SuccessMessage";
import ErrorMessage from "../visual/ErrorMessage";

const ContactForm: React.FC<StyledProps> = forwardRef<
  HTMLDivElement,
  StyledProps
>((props: StyledProps, ref) => {
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

  const validateOnChange: (e: ChangeEvent, onChange: (e: ChangeEvent) => void) => void = (e, onChange) => {
    const emailInput = document.querySelector("input#email") as HTMLInputElement;
    const phoneInput = document.querySelector("input#phone") as HTMLInputElement;

    setIsSubmitDisabled(emailInput.value.length <= 4 && phoneInput.value.length <= 4);

    onChange(e);
  }

  return (
    <NetlifyForm
      formProps={{ className: props.className }}
      honeypotName="bot-field"
      enableRecaptcha
      name="Contact"
      
      onSuccess={(response: any, context: any) => {
        context.formRef.current.reset();
        const success = document.querySelector("p#contact-success");

        if (!success) {
          return;
        }

        success.scrollIntoView({ block: "center" });
      }}
      onFailure={() => {
        const error = document.querySelector("p#contact-error");

        if (!error) {
          return;
        }

        error.scrollIntoView({ block: "center" });
      }}
    >
      {({
        handleChange,
        success,
        error,
      }: {
        handleChange: (e: ChangeEvent) => void;
        success: any;
        error: any;
      }) => (
        <>
          <Honeypot />
          <Recaptcha siteKey={process.env.GATSBY_SITE_RECAPTCHA_KEY} invisible />
          <div ref={ref} className="input-wrapper" style={{ height: "100%" }}>
            {success && <SuccessMessage />}
            {error && <ErrorMessage />}
            <Input
              id="name"
              icon={<PersonIcon />}
              iconStyle="stroke"
              label="Imię i nazwisko"
              onChange={(e) => validateOnChange(e, handleChange)}
            />
            <Input
              id="salon"
              icon={<SalonIcon />}
              iconStyle="fill"
              label="Nazwa gabinetu"
              onChange={(e) => validateOnChange(e, handleChange)}
            />
            <Input
              id="phone"
              icon={<PhoneIcon />}
              iconStyle="stroke"
              label="Numer telefonu"
              onChange={(e) => validateOnChange(e, handleChange)}
            />
            <Input
              id="email"
              icon={<EmailIcon />}
              iconStyle="fill"
              label="Adres e-mail"
              onChange={(e) => validateOnChange(e, handleChange)}
            />
            <TextArea onChange={handleChange} />
            <Button disabled={isSubmitDisabled} type="submit" colored>
              Wyślij
            </Button>
          </div>
        </>
      )}
    </NetlifyForm>
  );
});

const StyledContactForm = styled(ContactForm)`
  grid-area: form;
  position: relative;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    ${({ theme }) => theme.media.min.laptop`
    padding: 0 48px;
  `};
  }
`;

const AnimatedContactForm: React.FC = () => {
  return (
    <Reveal offset={30} mobileOffset={60} animationClass="form">
      <StyledContactForm />
    </Reveal>
  );
};

export default AnimatedContactForm;
