import React from "react";
import styled from "styled-components";
import Card from "components/Card";
import ReviewStars from "./ReviewStars";
import ReviewText from "./ReviewText";
import ReviewerTitle from "./ReviewerTitle";
import { ReviewImage, ReviewImageWrapper } from "./ReviewImage";
import { StyledProps } from "types/StyledProps";

const StyledCard = styled(Card)``;

interface ReviewCardProps extends StyledProps {
  imgSrc: string;
  stars: number;
  reviewer: string;
  review: string;
}

const ReviewCard: React.FC<ReviewCardProps> = (props: ReviewCardProps) => {
  return (
    <StyledCard className={props.className}>
      <ReviewImageWrapper>
        <ReviewImage src={props.imgSrc} />
      </ReviewImageWrapper>
      <ReviewStars stars={props.stars} />
      <ReviewerTitle>{props.reviewer}</ReviewerTitle>
      <ReviewText>{props.review}</ReviewText>
    </StyledCard>
  );
};

const StyledReviewCard = styled(ReviewCard)`
  width: 100%;
  margin-top: 25%;
  padding: 0;
  padding-top: 18%;

  ${({ theme }) => theme.media.min.tablet`
    margin-top: 8%;
    padding: 35px;
    padding-top: 20px;
  `}

  ${({ theme }) => theme.media.min.laptop`
    width: 60%;
  `}

  ${({ theme }) => theme.media.min.largeDesktop`
    width: 65%;
    margin: 0;
    margin-right: 20%;
  `}
`;

export default StyledReviewCard;
