import styled, { css } from "styled-components";
import { Link as GatsbyLink } from "gatsby";

const footerLinkStyle = css`
  all: unset;
  color: ${({ theme }) => theme.colors.light};
  font-size: 1rem;
  cursor: pointer;
`;

const Link = styled.a`
  ${footerLinkStyle}
`;

export const StyledGatsbyLink = styled(GatsbyLink)`
  ${footerLinkStyle}
`;

export default Link;
