import React from "react";
import styled from "styled-components";
import StarPercentage from "./StarPercentage";
import { StyledProps } from "types/StyledProps";
import StarsShape from "./StarsShape";

interface ReviewStarsProps extends StyledProps {
  stars: number;
}

const ReviewStars: React.FC<ReviewStarsProps> = (props: ReviewStarsProps) => {
  return (
    <svg
      className={props.className}
      viewBox="0 0 90 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <StarPercentage
        stars={props.stars}
        clipPath="url(#stars-clip)"
        fill="url(#a)"
      />
      <defs>
        <StarsShape />
        <linearGradient id="a" gradientTransform="rotate(90)">
          <stop offset="0%" stopColor="#4888E8" />
          <stop offset="100%" stopColor="#1658BA" />
        </linearGradient>
      </defs>
    </svg>
  );
};

const StyledReviewStars = styled(ReviewStars)`
  width: 35%;
  margin: 0 auto;

  ${({ theme }) => theme.media.min.tablet`
    width: 33%;
    margin-left: 0;
    margin-right: auto;
  `}

  ${({ theme }) => theme.media.min.laptop`
    width: 175px;
  `}
`;

export default StyledReviewStars;
