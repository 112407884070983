import React, { ChangeEvent, useRef } from "react";
import styled from "styled-components";
import { StyledProps } from "types/StyledProps";
import IconWrapper from "./IconWrapper";
import InnerInput from "./InnerInput";
import Label from "./Label";

interface InputProps extends StyledProps {
  id: string;
  label: string;
  icon: React.ReactElement;
  iconStyle: "stroke" | "fill";

  onChange: (e: ChangeEvent) => void;
}

const Input: React.FC<InputProps> = (props: InputProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  return (
    <div
      onMouseDown={(e) => e.target !== inputRef.current && e.preventDefault()}
      onClick={() => inputRef?.current?.focus()}
      className={props.className}
    >
      <InnerInput
        ref={inputRef}
        iconStyle={props.iconStyle}
        id={props.id}
        name={props.id}
        type="text"
        placeholder=" "
        onChange={props.onChange}
      />
      <Label htmlFor={props.id}>{props.label}</Label>
      <IconWrapper iconStyle={props.iconStyle}>{props.icon}</IconWrapper>
    </div>
  );
};

const StyledInput = styled(Input)`
  display: flex;
  position: relative;
  width: 100%;
  background: ${({ theme }) => theme.colors.light};
  box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.15);
  border-radius: 12px;
  margin: 18px auto;
  height: 58px;
  align-items: center;

  ::before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border-radius: 12px;
    opacity: 0;
    transition: opacity 0.25s;
    box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.2);
    z-index: -1;
  }

  ::after {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    bottom: 0;
    width: 100%;
    height: 8px;
    background: ${({ theme }) => theme.gradients.main(225)};
    border-radius: 0 0 12px 12px;
    opacity: 0;
    transform: translateX(-50%);
    transition: opacity 0.25s;
    z-index: 5;
  }

  :hover:not(:focus):not([disabled]),
  :focus-within {
    ::before {
      opacity: 1;
    }
  }

  :focus-within {
    input {
      outline: none;
    }

    path {
      ${({ iconStyle }) => `${iconStyle}: url(#a)`};
    }

    ::after {
      opacity: 1;
    }
  }

  // This animation is required to style autocomplete on webkit browsers
  @keyframes autofill {
    0% {
      box-shadow: 0 0 0px 100vw ${({ theme }) => theme.colors.light} inset;
    }
    100% {
      box-shadow: 0 0 0px 100vw ${({ theme }) => theme.colors.light} inset;
    }
  }
`;

export default StyledInput;
