import React from "react";
import styled, { withTheme, css } from "styled-components";
import { StyledProps } from "types/StyledProps";
import CardWrapper from "./CardWrapper";
import StatsCard from "./StatsCard";
import StatsHeader from "./StatsHeader";
import ContentWrapper from "./ContentWrapper";
import MiddleCardDots from "./visuals/MiddleCardDots";
import Heart from "./visuals/Heart";
import Like from "./visuals/Like";
import LeftBlob from "./visuals/LeftBlob";
import RightBlob from "./visuals/RightBlob";
import UpperCornerStroke from "./visuals/UpperCornerStroke";
import LowerCornerStroke from "./visuals/LowerCornerStroke";
import ChartLines from "./visuals/ChartLines";
import Reveal from "components/Reveal";

const StatsSection: React.FC<StyledProps> = (props: StyledProps) => {
  return (
    <section css={animations} className={props.className}>
      <LeftBlob />
      <RightBlob />
      <Reveal animationClass="stats-header">
        <StatsHeader centered colored>
          Statystyki
        </StatsHeader>
      </Reveal>
      <ContentWrapper>
        <CardWrapper>
          <StatsCard count={560} label={"Skutecznych kampanii"}>
            <Reveal animationClass="like">
              <Like />
            </Reveal>
          </StatsCard>
          <StatsCard
            customSuffix="m+"
            customPrecision={2}
            count={1.58}
            label={"Kliknięć w reklamy"}
          >
            <Reveal animationClass="dots-scale">
              <MiddleCardDots />
            </Reveal>
            <Reveal offset={20} mobileOffset={15} animationClass="upper-corner">
              <div>
                <UpperCornerStroke />
                <LowerCornerStroke />
              </div>
            </Reveal>
          </StatsCard>
          <StatsCard
            mobileAnimationOffset={30}
            count={115}
            label={"Zadowolonych klientów"}
          >
            <Reveal mobileOffset={20} offset={30} animationClass="heart">
              <Heart />
            </Reveal>
          </StatsCard>
        </CardWrapper>
        <ChartLines />
      </ContentWrapper>
    </section>
  );
};

const StyledStatsSection = styled(StatsSection)`
  position: relative;
  margin-top: 50%;
  padding-bottom: 50%;
  overflow: visible;
  z-index: 10;

  ${({ theme }) => theme.media.min.desktop`
    margin-top: 450px;
    padding-bottom: 0;
  `}
`;

const animations = css`
  .stats-header {
    animation: slide-in-bottom 0.85s both;
  }

  .upper-corner {
    opacity: 1;
  }

  .upper-corner > svg:first-of-type {
    animation: upper-corner 0.85s both;
    animation-delay: 1s;
  }

  .upper-corner > svg:last-of-type {
    animation: lower-corner 0.85s both;
    animation-delay: 1s;
  }

  @keyframes upper-corner {
    from {
      transform: scale(0.5);
      opacity: 0;
    }
    to {
      transform: translate(-50%, -50%);
      opacity: 1;
    }
  }

  @keyframes lower-corner {
    from {
      transform: scale(0.5) rotate(180deg);
      opacity: 0;
    }
    to {
      transform: translate(50%, 50%) rotate(180deg);
      opacity: 1;
    }
  }

  .heart {
    animation: heart 0.85s both;
    animation-delay: 0.5s;

    ${({ theme }) => theme.media.min.laptop`
      animation-delay: 1.5s;
    `}
  }

  @keyframes heart {
    from {
      transform: translate(50%, -50%) scale(0.8) rotate(-30deg);
      opacity: 0;
    }
    to {
      transform: translate(0, 0) rotate(-15deg);
      opacity: 1;
    }
  }

  .like {
    animation: like 0.85s both;
    animation-delay: 0.5s;

    ${({ theme }) => theme.media.min.laptop`
      animation-delay: 1.5s;
    `}
  }

  @keyframes like {
    from {
      transform: translate(-50%, 50%) scale(0.8) rotate(-30deg);
      opacity: 0;
    }
    to {
      transform: translate(0, 0) rotate(-15deg);
      opacity: 1;
    }
  }
`;

export default withTheme(StyledStatsSection);
