import styled from "styled-components";

const LinkWrapper = styled.li`
  margin: 24px 0;
  transition: transform 0.25s;

  :hover {
    transform: scale(1.1);
  }

  ${({ theme }) => theme.media.min.laptop`
    margin: 0 24px;
  `}
`;

export default LinkWrapper;
