import React, { useState } from "react";
import styled, { css } from "styled-components";
import Card from "components/Card";
import StatsCount from "./StatsCount";
import StatsLabel from "./StatsLabel";
import Reveal from "components/Reveal";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import { StyledProps } from "types/StyledProps";

interface StatsCardProps extends StyledProps {
  count: number;
  label: string;
  customSuffix?: string;
  customPrecision?: number;
  mobileAnimationOffset?: number;
}

const StatsCard: React.FC<StatsCardProps> = ({
  children,
  count,
  label,
  className,
  mobileAnimationOffset,
  customSuffix,
  customPrecision,
}) => {
  const [isCountUpActive, setIsCountUpActive] = useState(true);

  const startCountUp = (method: any, isVisible: boolean) => {
    if (isCountUpActive && isVisible) {
      method();
    }
  };

  return (
    <div className={className} css={animations}>
      <Reveal mobileOffset={mobileAnimationOffset} animationClass="card">
        <Card css={CardStyles} margin="36px auto">
          <StatsCount>
            <CountUp
              start={0}
              duration={2}
              end={count}
              separator={"\xa0"}
              decimals={customPrecision || 0}
              suffix={customSuffix || "+"}
              onEnd={() => {
                setIsCountUpActive(false);
              }}
            >
              {({ countUpRef, start }) => (
                <VisibilitySensor
                  onChange={(isVisible) => startCountUp(start, isVisible)}
                  delayedCall
                  scrollCheck
                >
                  <span ref={countUpRef} />
                </VisibilitySensor>
              )}
            </CountUp>
          </StatsCount>
          <StatsLabel>{label}</StatsLabel>
        </Card>
      </Reveal>
      {children}
    </div>
  );
};

const CardStyles = css`
  width: 100%;
  margin: 0;
  padding: 36px;

  ${({ theme }) => theme.media.min.desktop`
    width: 100%;
    max-width: 350px;
  `}
`;

const StyledStatsCard = styled(StatsCard)`
  position: relative;
  width: 60%;
  max-width: 250px;
  margin: 36px auto;

  ${({ theme }) => theme.media.min.desktop`
    width: 100%;
    max-width: 300px;
  `}
`;

const animations = css`
  .card {
    animation: card 0.85s both;
  }

  @keyframes card {
    from {
      transform: scale(0);
      opacity: 0;
    }
    to {
      transform: scale(1);
      opacity: 1;
    }
  }
`;

export default StyledStatsCard;
