import styled from "styled-components";

const InnerInput = styled.input<{ iconStyle: string }>`
  width: 100%;
  padding-left: 65px;
  padding-right: 10px;
  font-size: 1rem;
  border: none;
  border-radius: 12px;
  color: ${({ theme }) => theme.colors.mainMid};

  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-internal-autofill-previewed,
  :-internal-autofill-selected {
    -webkit-text-fill-color: ${({ theme }) => theme.colors.mainMid};
    animation-name: autofill;
    // Safari support - any positive time runs instantly
    animation-delay: 1s;
    animation-fill-mode: both;
    z-index: 1;

    + label {
      z-index: 10;
    }

    :-webkit-autofill::first-line {
      font-size: 1rem;
    }
  }

  :focus + label,
  &:not(:placeholder-shown) + label {
    top: -1.5rem;

    background: -webkit-linear-gradient(
      ${({ theme }) => `${theme.colors.mainLight}, ${theme.colors.mainDark}`}
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    transform: translateX(-45%) scale(0.75);
  }

  :not(:placeholder-shown) ~ div path {
    ${({ iconStyle }) => `${iconStyle}: url(#a)`};
  }
`;

export default InnerInput;
