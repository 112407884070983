import React from "react";
import styled, { css } from "styled-components";
import AboutCard from "../visuals/AboutCard";
import CardTitle from "../CardTitle";
import CardDescription from "../CardDescription";
import AboutCardIcon from "../visuals/AboutCardIcon";
import FirstCardDots from "./FirstCardDots";
import FirstCardX from "./FirstCardX";
import { StyledProps } from "types/StyledProps";
import Reveal from "components/Reveal";

const FirstCard: React.FC<StyledProps> = (props: StyledProps) => {
  return (
    <div css={animations} className={props.className}>
      <Reveal offset={25} mobileOffset={60} animationClass="dots-scale">
        <FirstCardDots />
      </Reveal>
      <Reveal animationClass="x">
        <FirstCardX />
      </Reveal>
      <Reveal animationClass="about-card">
        <AboutCard margin="0px auto">
          <AboutCardIcon icon="website" />
          <CardTitle>Strony internetowe</CardTitle>
          <CardDescription>
            W erze cyfryzacji, strona internetowa jest niesamowicie ważna - to
            ona najlepiej pokaże Twój profesjonalizm.
          </CardDescription>
        </AboutCard>
      </Reveal>
    </div>
  );
};

const StyledFirstCard = styled(FirstCard)`
  flex: 1;
  margin: 0px auto;
  position: relative;

  ${({ theme }) => theme.media.below.laptop`
    order: 2;
  `}

  ${({ theme }) => theme.media.min.laptop`
    margin: 56px auto;
  `}
`;

const animations = css`
  .about-card {
    animation: slide-in-bottom 0.85s both;

    ${({ theme }) => theme.media.min.laptop`
      animation: slide-in-right 0.85s both;
      animation-delay: 0.75s;
    `}
  }

  .x {
    animation: x 0.85s both ease-out;

    ${({theme}) => theme.media.min.laptop`
      animation: x-laptop 1.5s both ease-out;
      animation-delay: 0.75s;
    `}

    @keyframes x {
      from {
        opacity: 0;
        transform: scale(0) rotate(-225deg);
      }
      to {
        opacity: 1;
        transform: scale(1) rotate(0deg);
      }
    }

    @keyframes x-laptop {
      from {
        opacity: 0;
        transform: translate(50px, 50px) rotate(-225deg);
      }
      to {
        opacity: 1;
        transform: translate(0, 0) rotate(0deg);
      }
    }
  }
`;

export default StyledFirstCard;
