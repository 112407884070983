import React from "react";
import styled from "styled-components";
import { ReactComponent as TargetIcon } from "assets/svg/icons/target.svg";
import { ReactComponent as WebsiteIcon } from "assets/svg/icons/website.svg";
import { ReactComponent as DesignIcon } from "assets/svg/icons/design.svg";
import { StyledProps } from "types/StyledProps";

interface AboutCardIconProps extends StyledProps {
  icon: "target" | "website" | "design";
}

const AboutCardIcon: React.FC<AboutCardIconProps> = (
  props: AboutCardIconProps
) => {
  const getIcon = () => {
    switch (props.icon) {
      case "target":
        return TargetIcon;
      case "website":
        return WebsiteIcon;
      case "design":
        return DesignIcon;
      default:
        return TargetIcon;
    }
  };

  return React.createElement(getIcon(), { className: props.className });
};

const StyledAboutCardIcon = styled(AboutCardIcon)`
  max-width: 50px;
  height: 50px;
`;

export default StyledAboutCardIcon;
