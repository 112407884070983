import styled from "styled-components";
import { ReactComponent as Logo } from "assets/svg/logo.svg";

const StyledLogo = styled(Logo)`
  width: 175px;
  margin: 0 auto;
  margin-top: 48px;
  fill: ${({ theme }) => theme.colors.light};

  ${({ theme }) => theme.media.min.laptop`
    margin: 0;
  `}
`;

export default StyledLogo;
