import React, { MouseEventHandler } from "react";
import styled from "styled-components";
import { StyledProps } from "types/StyledProps";
import { ReactComponent as SliderArrowRight } from "./slider-arrow-right.svg";

interface SliderArrowProps extends StyledProps {
  rotation: "left" | "right";
  disabled?: boolean;

  onClick: () => void;
}

const SliderArrow: React.FC<SliderArrowProps> = (props: SliderArrowProps) => {
  return (
    <SliderArrowRight onMouseDown={(e) => e.preventDefault()} onClick={props.onClick} className={props.className} />
  );
};

const StyledSliderArrow = styled(SliderArrow)`
  width: 36px;
  margin: 24px 12px;
  cursor: pointer;
  transform: rotate(
    ${({ rotation }) => (rotation === "left" ? "180deg" : "0")}
  );

  opacity: ${({ disabled }) => disabled ? 0.5 : 1};
`;

export default StyledSliderArrow;
