import React, { useState, useEffect } from "react";
import theme from "styles/theme";
import { useScrollPosition } from "utils/useScrollPosition";

function GetViewportHeight() {
  return Math.max(
    document.documentElement.clientHeight,
    window.innerHeight || 0
  );
}

type Props = {
  animationClass: string;
  offset?: number;
  mobileOffset?: number;
  cascade?: boolean;
};

const Reveal: React.FC<Props> = (props) => {
  const { children, animationClass, offset, mobileOffset, cascade } = props;
  const [childClass, setChildClass] = useState("");
  const [triggerPosition, setTriggerPosition] = useState(0);
  let refArray: React.ReactNode[] = [];

  useEffect(() => {
    const ref = refArray[0] as any;
    const element = ref.current as HTMLElement;

    if (!element) {
      return;
    }

    const rect = element.getBoundingClientRect();

    if (rect.top < GetViewportHeight()) {
      setChildClass(animationClass);
    } else {
      setTriggerPosition(rect.top || 0);
    }
  }, [setTriggerPosition, refArray, cascade, animationClass]);

  useScrollPosition(({ prevPos, currPos }) => {
    let scrolledDown = prevPos.y >= currPos.y;

    if (scrolledDown) {
      let specificOffset = (window.innerWidth < theme.breakpoints.laptop ? mobileOffset : offset) || 40;
      const offsetHeight = GetViewportHeight() * (specificOffset / 100);
      const scrollPosition = currPos.y * -1 + GetViewportHeight();
      if (scrollPosition > triggerPosition + offsetHeight) {
        setChildClass(animationClass);
      }
    }
  });

  let i = 0;

  function addClassesToChild(child: React.ReactNode, className: string) {
    const childElement = child as React.ReactElement;
    refArray[i] = React.createRef();
    const previousProps = childElement.props;

    if (previousProps.className === className) {
      return;
    }

    let previousClassName;
    if (previousProps.className === undefined) {
      previousClassName = "";
    } else {
      previousClassName = previousProps.className + " ";
    }

    const props = {
      ...previousProps,
      className: previousClassName + "faded " + className,
      ref: refArray[i],
    };

    i++;
    return React.cloneElement(childElement, props);
  }

  return (
    <>
      {React.Children.map(children, (child) =>
        addClassesToChild(child, childClass)
      )}
    </>
  );
};

export default Reveal;
