import styled from "styled-components";
import { ReactComponent as ContactIllustration } from "assets/svg/contact-illustration.svg";

const StyledContactIllustration = styled(ContactIllustration)`
  margin-top: 75px;
  grid-area: illustration;

  ${({ theme }) => theme.media.min.laptop`
    margin-top: 0;
  `}
`;

export default StyledContactIllustration;
