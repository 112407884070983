import styled from "styled-components";

const Card = styled.article<{ margin?: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 80%;
  padding: 48px 32px;
  margin: ${({ margin }) => margin || "16px auto"};
  background-color: ${({ theme }) => theme.colors.light};
  border-radius: 36px;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.1);

  ${({theme}) => theme.media.min.laptop`
    padding: 56px;
    border-radius: 48px;
  `}
`;

export default Card;
