import styled from "styled-components";
import Paragraph from "components/Paragraph";

const AboutParagrapgh = styled(Paragraph)`
  display: none;
  margin-top: 1em;

  ${({ theme }) => theme.media.min.laptop`
    display: block;
  `}
`;

export default AboutParagrapgh;
