import styled from "styled-components";

const StyledActionGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  text-align: center;

  ${({ theme }) => theme.media.min.laptop`
    align-items: start;
    width: 45%;
    text-align: left;
  `}
`;

export default StyledActionGroup;