import styled from "styled-components";
import { ReactComponent as ReviewsWave } from "./reviews-wave.svg";

const TopReviewsWave = styled(ReviewsWave)`
  transform-origin: top;
  transform: translateY(-5px);

  ${({theme}) => theme.media.min.laptop`
    transform: scaleY(0.5) translateY(-3px);
  `}
`;

export default TopReviewsWave;
