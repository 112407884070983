import React from "react";
import styled from "styled-components";
import { StyledProps } from "types/StyledProps";

const ErrorMessage: React.FC<StyledProps> = (props: StyledProps) => {
  return <p id="contact-error" className={props.className}>Wystąpił błąd. <br /> Spróbuj ponownie później.</p>;
};

const StyledErrorMessage = styled(ErrorMessage)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;
  color: ${({ theme }) => theme.colors.light};
  background: #fb3d3d;
  border-radius: 16px;
  line-height: 2;
  animation: slide-in-origin 1s both;
  text-align: center;
  margin: 24px auto;

  ${({ theme }) => theme.media.min.laptop`
    z-index: 3;
    font-size: 1.5rem;
  `}
`;

export default StyledErrorMessage;
