import styled from "styled-components";
import gradientFonts from "./gradientFonts";

const StatsLabel = styled.h4`
  ${gradientFonts}

  font-size: 0.75rem;

  ${({ theme }) => theme.media.min.desktop`
    font-size: 1rem;
  `}
`;

export default StatsLabel;
