import React from "react";
import styled, { css } from "styled-components";
import AboutCard from "../visuals/AboutCard";
import CardTitle from "../CardTitle";
import CardDescription from "../CardDescription";
import AboutCardIcon from "../visuals/AboutCardIcon";
import Reveal from "components/Reveal";
import { StyledProps } from "types/StyledProps";

const MiddleCard: React.FC<StyledProps> = (props: StyledProps) => {
  return (
    <div css={animations} className={props.className}>
      <Reveal animationClass="about-card">
        <AboutCard margin="56px auto">
          <AboutCardIcon icon="target" />
          <CardTitle>Pozyskiwanie klientów</CardTitle>
          <CardDescription>
            Dzięki specjalnym narzędziom oferowanym przez Facebook i Instagram,
            dotrzemy do potencjalnych klientów Twojego gabinetu!
          </CardDescription>
        </AboutCard>
      </Reveal>
    </div>
  );
};

const StyledMiddleCard = styled(MiddleCard)`
  flex: 1;

  ${({ theme }) => theme.media.below.laptop`
    order: 1;
  `}

  ${({ theme }) => theme.media.onlyOn.laptop`
    transform: scale(1.2);
  `}

  ${({ theme }) => theme.media.min.laptop`
    top: 50px;
    height: 100%;
    margin: 0px auto;
    z-index: 1;
    transform: scale(1.15);
  `}
`;

const animations = css`
  .about-card {
    animation: slide-in-bottom 0.85s both;

    ${({ theme }) => theme.media.min.laptop`
      animation: slide-in-origin 0.85s both;
      animation-delay: 0.5s;
    `}
  }
`;

export default StyledMiddleCard;
