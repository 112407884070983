import styled from "styled-components";
import { ReactComponent as ThickX } from "assets/svg/thick-x.svg";

const FirstCardX = styled(ThickX)`
  position: absolute;
  left: 10px;
  bottom: -35px;
  width: 25px;
  transform: rotate(-20deg);
  z-index: -1;

  ${({theme}) => theme.media.min.laptop`
    width: 35px;
    left: -50px;
    top: -100px;
    bottom: revert;
  `}
`;

export default FirstCardX;
