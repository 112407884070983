import React, { forwardRef, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { StyledProps } from "types/StyledProps";
import theme from "styles/theme";
import SwiperCore, { EffectCreative } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import StyledSliderArrowWrapper from "./visual/SliderArrowWrapper";
import useResizeObserver from "use-resize-observer";

SwiperCore.use([EffectCreative]);

const CardWrapper: React.FC<StyledProps> = forwardRef((props, ref) => {
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const swiperRef = useRef<SwiperCore | null>(null);

  const [activeIndex, setActiveIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(true);

  const { ref: wrapperElemRef, width = 1 } =
    useResizeObserver<HTMLDivElement>();

  useEffect(() => {
    ref = wrapperRef;
  }, []);

  useEffect(() => {
    setIsMobile(window.innerWidth < theme.breakpoints.laptop);
  }, [width]);

  const pxTransformRegEx = /-?(\d)+(\.?\d)*px/g;
  const percentTransformRegEx = /-?(\d)+(\.?\d)*%/g;
  const mobileMinCardOpacity = 0.25;

  const getCardTransform = (swiper: SwiperCore, index: number) =>
    (swiper.slides[index] as HTMLDivElement).style.transform;

  const getTransformTranslate3d = (transform: string) =>
    transform.substring(
      transform.indexOf("translate3d"),
      transform.indexOf("rotateX(") - 1
    );

  const getCurrentTransformX = (
    currentTranslate3d: string
  ): [string[], number, number] => {
    const currentTransform = currentTranslate3d.split("calc(");

    const px = currentTransform[1]
      .replaceAll(" ", "")
      .matchAll(pxTransformRegEx);
    const pxarray = Array.from(px);
    const pxStr = pxarray[0][0];

    const percent = currentTransform[1].matchAll(percentTransformRegEx);
    const percentarray = Array.from(percent);
    let percentStr = percentarray[0]?.[0];

    if (!percentStr) {
      percentStr = "0%";
    }

    const currentTransformXPx = parseInt(
      pxStr.substring(0, currentTransform[1].indexOf("p"))
    );

    let currentTransformXPercent = parseFloat(
      percentStr.substring(0, percentarray[0]?.[0].indexOf("%"))
    );

    return [currentTransform, currentTransformXPx, currentTransformXPercent];
  };

  const getCurrentTransformZ = (
    currentTransform: string[],
    transform: string
  ) => parseInt(currentTransform[3].substring(0, transform.indexOf("p")));

  const getNextSlideEndOffset = (swiper: SwiperCore) => {
    if (!wrapperRef?.current) {
      return "100%";
    }

    const rect = swiper.el.getBoundingClientRect();

    return window.innerWidth - rect.left;
  };

  return isMobile ? (
    <div
      ref={(instance) => {
        wrapperRef.current = instance;
        wrapperElemRef(instance);
      }}
      className={props.className}
    >
      {React.cloneElement(
        <Swiper
          grabCursor
          onActiveIndexChange={(swiper) => setActiveIndex(swiper.activeIndex)}
          slidesPerView={1.33}
          spaceBetween={25}
          centeredSlides
          onInit={(swiper) => {
            swiperRef.current = swiper;
          }}
          onTransitionStart={(swiper) => {
            for (let i = 0; i < swiper.slides.length; i += 1) {
              (
                swiper.slides[i] as HTMLDivElement
              ).style.opacity = `${mobileMinCardOpacity}`;
            }

            (
              swiper.slides[swiper.activeIndex] as HTMLDivElement
            ).style.opacity = `1`;
          }}
          onSetTranslate={(swiper, translate) => {
            if (!wrapperRef?.current) {
              return;
            }
            const middle = wrapperRef.current.getBoundingClientRect().width / 2;

            for (let i = 0; i < swiper.slides.length; i += 1) {
              const bound = swiper.slides[i].getBoundingClientRect();
              const rectMiddle = bound.left + bound.width / 2;
              const distance = middle - rectMiddle;
              const percentage = distance / middle;

              (swiper.slides[i] as HTMLDivElement).style.opacity = `${Math.max(
                mobileMinCardOpacity,
                1 - Math.abs(percentage)
              )}`;
            }
          }}
          observer
        >
          {React.Children.map(props.children, (child) => (
            <SwiperSlide>{child}</SwiperSlide>
          ))}
        </Swiper>
      )}
    </div>
  ) : (
    <div
      ref={(instance) => {
        wrapperRef.current = instance;
        wrapperElemRef(instance);
      }}
      className={props.className}
    >
      {/*
        For some reason, this non breaking space is causing
        swiper to rerender. Removing it breaks its responsiveness.
        Don't ask me why, but we need it here.
        (probably some React/Swiper memoization?)
      */}
      &nbsp;
      <Swiper
        grabCursor
        observeParents
        onActiveIndexChange={(swiper) => setActiveIndex(swiper.activeIndex)}
        onInit={(swiper) => {
          swiperRef.current = swiper;

          for (let i = 0; i < swiper.slides.length; i += 1) {
            (swiper.slides[i] as HTMLDivElement).style.transformOrigin = "left";
          }

          swiper.slideTo(swiper.slides.length - 1, 0);
          swiper.update();
        }}
        onResize={(swiper) => {
          if (!swiper?.params?.creativeEffect?.next?.translate) {
            return;
          }

          swiper.params.creativeEffect.next.translate[0] =
            getNextSlideEndOffset(swiper);

          swiper.update();
        }}
        onSetTranslate={(swiper, translate) => {
          for (let i = 0; i < swiper.slides.length; i += 1) {
            const cardOffset = 25;
            const transform = getCardTransform(swiper, i);

            if (!transform) {
              return;
            }

            const currentTranslate3d = getTransformTranslate3d(transform);

            const [
              currentTransform,
              currentTransformXPx,
              currentTransformXPercent,
            ] = getCurrentTransformX(currentTranslate3d);

            const currentTransformZ = getCurrentTransformZ(
              currentTransform,
              transform
            );

            const el = swiper.slides.eq(i);
            // Typescript doesn't have definition for progress,
            // although it works and is described in Swiper docs
            const progress = (el[0] as any).progress;
            const maxX =
              currentTransformXPx - Math.max(progress * cardOffset, 0);
            var isFirefox =
              navigator.userAgent.toLowerCase().indexOf("firefox") > -1;

            const transformXFinal = isFirefox
              ? `${currentTransformXPercent}% + ${maxX}px`
              : `${maxX}px + ${currentTransformXPercent}%`;

            const final = `${
              currentTransform[0]
            }calc(${transformXFinal}), calc(0px), calc(${currentTransformZ}px)) rotateX(0deg) rotateY(0deg) rotateZ(0deg) scale(${
              1 - Math.max(progress * 0.2, 0)
            })`;

            (swiper.slides[i] as HTMLDivElement).style.transform = final;
            (swiper.slides[i] as HTMLDivElement).style.opacity = `${Math.min(
              Math.max(1 - progress * 0.4, 0),
              1
            )}`;
          }
        }}
        effect={"creative"}
        creativeEffect={{
          perspective: true,
          prev: {
            shadow: false,
            opacity: 0.25,
            translate: ["-1.5%", 0, -25],
          },
          next: {
            translate: ["100%", 0, 0],
          },
        }}
      >
        {React.Children.map(props.children, (child) => (
          <SwiperSlide>{child}</SwiperSlide>
        ))}
      </Swiper>
      <StyledSliderArrowWrapper
        swiperRef={swiperRef}
        activeIndex={activeIndex}
      />
    </div>
  );
});

const StyledCardWrapper = styled(CardWrapper)`
  position: relative;
  width: 100%;
  height: 100%;

  ${({ theme }) => theme.media.min.largeDesktop`
    width: 66%;
    display: inline-block;
    overflow: visible !important;
    transform: translateX(40%);
  `}
`;

export default StyledCardWrapper;
