import React from "react";
import styled from "styled-components";
import ReferralLink from "./ReferralLink";
import { StyledProps } from "types/StyledProps";

const Referral: React.FC<StyledProps> = (props: StyledProps) => {
  return (
    <p className={props.className}>
      Wykonane z ❤ przez Miłosza Kowalskiego <br />
      <ReferralLink
        href="https://mkowalski.dev"
        target="_blank"
        rel="noreferrer"
      >
        mkowalski.dev
      </ReferralLink>
    </p>
  );
};

const StyledReferral = styled(Referral)`
  margin: 0;
  text-align: center;
  line-height: 1.5;
  color: ${({ theme }) => theme.colors.light};
`;

export default StyledReferral;
