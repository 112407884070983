import styled from "styled-components";

const ReviewerTitle = styled.h3`
  width: 75%;
  margin: 0;
  margin-top: 0.25em;
  font-size: 1.5rem;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  text-align: center;

  background: -webkit-linear-gradient(
    ${({ theme }) => `${theme.colors.mainLight}, ${theme.colors.mainDark}`}
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  ${({ theme }) => theme.media.min.mobile`
    font-size: 2rem;
  `}

  ${({ theme }) => theme.media.min.tablet`
    text-align: left;
    align-self: start;
    margin-bottom: 35px;
  `}
`;

export default ReviewerTitle;
