import styled from "styled-components";
import Header from "components/Header";

const StatsHeader = styled(Header)`
  margin-bottom: 25px;

  ${({ theme }) => theme.media.min.desktop`
    margin-bottom: 75px;
    position: relative;
  `}
`;

export default StatsHeader;
