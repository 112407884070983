import styled from "styled-components";

const ContentWrapper = styled.div`
  padding: 15% 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ theme }) => theme.media.min.largeDesktop`
    max-width: ${theme.maxWidth + 250}px;
    flex-direction: row;
    margin: 0 auto;
    padding: 0;
  `}
`;

export default ContentWrapper;
