import React from "react";
import styled, { css } from "styled-components";
import { StyledProps } from "types/StyledProps";

interface MenuIconProps extends StyledProps {
  isExpanded: boolean;
  isDocked: boolean;

  onClick: () => void;
}

const MenuIcon: React.FC<MenuIconProps> = (props: MenuIconProps) => {
  return (
    <div className={props.className} onClick={props.onClick} id="expand-icon">
      <div className="line"></div>
      <div className="line"></div>
      <div className="line"></div>
    </div>
  );
};

const StyledMenuIcon = styled(MenuIcon)`
  display: none;
  padding-top: 5px;
  margin-right: 20px;
  margin-left: auto;
  cursor: pointer;

  .line {
    height: 4px;
    width: 40px;
    margin: 8px 0;
    background-color: ${({ theme, isDocked }) => isDocked ? theme.colors.light : theme.colors.mainMid};
    border-radius: 100vw;
    transition: transform 0.5s, opacity 0.5s, background-color 0.5s;
  }

  ${({ isExpanded }) =>
    isExpanded &&
    css`
      div {
        &:first-child {
          transform: translateY(12px) rotate(-45deg);
        }

        &:nth-child(2) {
          opacity: 0;
        }

        &:last-child {
          transform: translateY(-12px) rotate(45deg);
        }
      }
    `}

  ${({ theme }) => theme.media.below.laptop`
    display: block;
  `}
`;

export default StyledMenuIcon;
