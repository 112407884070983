import React, { useEffect } from "react";
import styled from "styled-components";
import Adrian from "assets/images/adrian.png";
import { StyledProps } from "types/StyledProps";

interface PersonalPhotoProps extends StyledProps {
  setImgsLoaded: (isLoaded: boolean) => void;
}

const PersonalPhoto: React.FC<PersonalPhotoProps> = (props: PersonalPhotoProps) => {
  useEffect(() => {
    const loadImage = (image: any) => {
      return new Promise((resolve, reject) => {
        const loadImg = new Image();
        loadImg.src = image;
        loadImg.onload = () => resolve(image);
        loadImg.onerror = (err) => reject(err);
      });
    };

    loadImage(Adrian)
      .then(() => props.setImgsLoaded(true))
      .catch((err) => console.log("Failed to load images", err));
  }, []);

  return <img className={props.className} src={Adrian} />;
};

const StyledPersonalPhoto = styled(PersonalPhoto)`
  position: absolute;
  bottom: 7.1%;
  left: 33.25%;
  width: 47.75%;
`;

export default StyledPersonalPhoto;
