import styled from "styled-components";

const PersonalPhotoWrapper = styled.div`
  width: 100%;
  max-width: 550px;
  margin: -25% 50px 50px 0;
  transform-origin: center;
  transform: scale(1.2);

  ${({ theme }) => theme.media.min.laptop`
    margin-top: -150px;
    width: 40%;
  `}
`;

export default PersonalPhotoWrapper;