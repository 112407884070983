import styled from "styled-components";
import { ReactComponent as Blob } from "assets/svg/blob.svg";

const LeftBlob = styled(Blob)`
  position: absolute;
  width: 75%;
  transform: translate(-60%, -75%);

  ${({ theme }) => theme.media.min.desktop`
    top: -10%;
    left: 0;
    max-width: 500px;
    transform: translate(-40%, -50%);
  `}
`;

export default LeftBlob;
