import styled from "styled-components";
import { ReactComponent as Blob } from "assets/svg/blob.svg";

const RightBlob = styled(Blob)`
  position: absolute;
  width: 75%;
  transform: translateX(100%);
  bottom: 0;

  ${({ theme }) => theme.media.min.desktop`
    top: -15%;
    right: 0;
    max-width: 500px;
    transform: translate(50%, -125%);
  `}
`;

export default RightBlob;
