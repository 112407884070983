import React from "react";
import styled from "styled-components";
import CompanyData from "./CompanyData";
import Navigation from "./Navigation";
import Referral from "./Referral";
import { ReactComponent as Wave } from "./visual/wave.svg";
import { StyledProps } from "types/StyledProps";

const HorizontalRule = styled.hr`
  all: unset;
  display: block;
  position: absolute;
  left: 50%;
  width: 75%;
  height: 1px;
  max-width: calc(${({ theme }) => theme.maxWidth}px - 150px);
  margin: 0 auto;
  background: ${({ theme }) => theme.colors.light};
  transform: translateX(-50%);
`;

const Footer: React.FC<StyledProps> = (props: StyledProps) => {
  return (
    <footer className={props.className}>
      <Wave />
      <Navigation />
      <HorizontalRule />
      <CompanyData />
      <Referral />
    </footer>
  );
};

const StyledFooter = styled(Footer)`
  padding-bottom: 48px;
  background: ${({ theme }) => theme.gradients.default};
`;

export default StyledFooter;
