import styled from "styled-components";

const Button = styled.button<{ colored?: boolean }>`
  position: relative;
  width: max-content;
  padding: 1.25em 3em;
  z-index: 1;
  color: ${({ theme, colored }) =>
    colored ? theme.colors.light : theme.colors.mainMid};
  background: ${({ theme, colored }) =>
    colored ? theme.gradients.main(225) : theme.colors.light};
  background-size: 150%;
  background-position: left;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  text-transform: uppercase;
  border: none;
  border-radius: 100vw;
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.25s, background-position 0.25s;

  ${({ theme }) => theme.media.min.laptop`
    padding: 1.5em 4em;
  `}

  ::after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 100vw;
    box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.15);
    opacity: 0;
    transition: opacity 0.25s;
  }

  :hover {
    transform: scale(1.1) !important;
    background-position: right;

    ::after {
      opacity: 1;
    }
  }

  :disabled {
    transform: revert !important;
    background: gray;
    cursor: default;
  }

  :disabled:hover {
    transform: revert !important;
  }
`;

export default Button;
