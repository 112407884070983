import React from "react";
import styled, { css } from "styled-components";
import Header from "components/Header";
import Description from "./Description";
import ContactForm from "./ContactForm";
import ContactIllustration from "./visual/ContactIllustration";
import ContentGrid from "./ContentGrid";
import { StyledProps } from "types/StyledProps";
import Reveal from "components/Reveal";

const ContactSection: React.FC<StyledProps> = (props: StyledProps) => {
  return (
    <section css={animations} id="contact" className={props.className}>
      <Reveal offset={40} mobileOffset={60} animationClass="header">
        <Header colored centered>
          Skontaktuj się z nami
        </Header>
      </Reveal>
      <ContentGrid>
        <Description />
        <ContactForm />
        <Reveal offset={20} animationClass="illustration">
          <div>
            <ContactIllustration />
          </div>
        </Reveal>
      </ContentGrid>
    </section>
  );
};

const StyledContactSection = styled(ContactSection)`
  max-width: ${({ theme }) => theme.maxWidth}px;
  margin: 0 auto;
  margin-top: 100px;
  padding: 48px;
  scroll-margin-top: 75px;
`;

const animations = css`
  .header {
    animation: slide-in-origin 0.85s both;
  }

  .illustration {
    animation: slide-in-origin 0.85s both;

    ${({ theme }) => theme.media.min.laptop`
      animation-delay: 1s;
    `}
  }
`;

export default StyledContactSection;
