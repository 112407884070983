import React, { useState, useEffect } from "react";
import styled, {
  withTheme,
  css,
  FlattenSimpleInterpolation,
} from "styled-components";
import Header from "components/Header";
import Paragraph from "components/Paragraph";
import Button from "components/Button";
import ActionGroup from "./ActionGroup";
import ContentWrapper from "./ContentWrapper";
import WavesWrapper from "./visuals/WavesWrapper";
import HeroArrow from "./visuals/HeroArrow";
import PersonalPhotoWrapper from "./visuals/PersonalPhotoWrapper";
import PersonalPhoto from "./visuals/PersonalPhoto";
import { ReactComponent as PersonalPhotoSvg } from "assets/svg/personal-photo.svg";
import { StyledProps } from "types/StyledProps";
import Reveal from "components/Reveal";

const HeroSection: React.FC<StyledProps> = (props: StyledProps) => {
  const [imgsLoaded, setImgsLoaded] = useState(false);
  const [animationsCss, setAnimationsCss] =
    useState<FlattenSimpleInterpolation>();

  useEffect(() => {
    if (imgsLoaded) {
      setAnimationsCss(animations);
    }
  }, [imgsLoaded]);

  return (
    <section css={animationsCss} className={props.className}>
      <WavesWrapper>
        <ContentWrapper>
          <ActionGroup>
            <Reveal animationClass="header">
              <Header large>
                Wespnij się z nami na <em>wyżyny</em> biznesu!
              </Header>
            </Reveal>
            <Reveal animationClass="paragraph">
              <Paragraph spaced>
                Pomożemy Ci znaleźć klientów na zabiegi i zadbamy o markę
                Twojego gabinetu. Zapełnijmy wspólnie wszystkie wolne terminy!
              </Paragraph>
            </Reveal>
            <Reveal animationClass="button">
              <Button
                onClick={() => {
                  var aboutSection = document.getElementById("about");

                  if (!aboutSection) {
                    scrollTo(0, window.innerHeight);
                    return;
                  }

                  aboutSection.scrollIntoView();
                }}
              >
                więcej
              </Button>
            </Reveal>
            <HeroArrow />
          </ActionGroup>
          <Reveal animationClass="personal-photo">
            <PersonalPhotoWrapper>
              <PersonalPhotoSvg />
              <PersonalPhoto setImgsLoaded={setImgsLoaded} />
            </PersonalPhotoWrapper>
          </Reveal>
        </ContentWrapper>
      </WavesWrapper>
    </section>
  );
};

const StyledHeroSection = styled(HeroSection)`
  width: 100%;
  height: 105vh;
  background: ${({ theme }) => theme.gradients.main(180)};
  margin: 0;
`;

const animations = css`
  .header,
  .paragraph {
    animation: slide-in-bottom 0.85s both;
  }

  .paragraph {
    animation-delay: 0.4s;
  }

  .button {
    animation: slide-in-origin 0.85s both;
    animation-delay: 0.8s;
  }

  .personal-photo {
    opacity: 1;
  }

  .personal-photo img {
    animation: slide-in-origin 0.85s both;
    animation-delay: 1.5s;
  }

  .personal-photo svg {
    animation: slide-in-origin 0.85s both;
    animation-delay: 0.4s;
    overflow: visible;

    path {
      transform-origin: center;
    }

    path:nth-of-type(1) {
      animation: triangle 0.85s both;
      animation-delay: 0.6s;
    }

    path:nth-of-type(2) {
      animation: triangle 0.85s both;
      animation-delay: 0.8s;
    }

    path:nth-of-type(3) {
      animation: triangle 0.85s both;
      animation-delay: 1s;
    }

    path:nth-of-type(4) {
      animation: slide-in-origin 0.85s both;
      animation-delay: 1.4s;
    }

    path:nth-of-type(5) {
      animation: zigzag 4s both;
      animation-delay: 1.25s;
    }

    path:nth-of-type(6) {
      transform-origin: 50% 75%;
      animation: cross 0.85s both;
      animation-delay: 1.4s;
    }

    @keyframes triangle {
      from {
        transform: rotate(35deg) scale(0.8);
        opacity: 0;
      }
      to {
        transform: rotate(0deg);
      }
    }

    @keyframes cross {
      from {
        transform: scale(0);
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }

    @keyframes zigzag {
      from {
        stroke-dasharray: 400;
        stroke-dashoffset: 400;
      }
      to {
        stroke-dashoffset: 0;
      }
    }
  }
`;

export default withTheme(StyledHeroSection);
