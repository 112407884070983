import React from "react";
import styled, { css } from "styled-components";
import Reveal from "components/Reveal";
import AboutCard from "../visuals/AboutCard";
import CardTitle from "../CardTitle";
import CardDescription from "../CardDescription";
import AboutCardIcon from "../visuals/AboutCardIcon";
import { StyledProps } from "types/StyledProps";

const LastCard: React.FC<StyledProps> = (props: StyledProps) => {
  return (
    <div css={animations} className={props.className}>
      <Reveal animationClass="about-card">
        <AboutCard className={props.className} margin="56px auto">
          <AboutCardIcon icon="design" />
          <CardTitle>Projekty graficzne</CardTitle>
          <CardDescription>
            Potrzebujesz logo, wizytówki, ilustracji lub całego brandingu? Pozwól
            naszym specjalistom uczynić je pięknymi.
          </CardDescription>
        </AboutCard>
      </Reveal>
    </div>
  );
};

const StyledLastCard = styled(LastCard)`
  flex: 1;

  ${({ theme }) => theme.media.below.laptop`
    order: 3;
  `}
`;

const animations = css`
  .about-card {
    animation: slide-in-bottom 0.85s both;

    ${({ theme }) => theme.media.min.laptop`
      animation: slide-in-left 0.85s both;
      animation-delay: 0.75s;
    `}
  }
`;

export default StyledLastCard;
