import styled from "styled-components";

const Label = styled.label`
  position: absolute;
  top: 50%;
  left: 65px;
  font-size: 1rem;
  z-index: 1;
  cursor: text;
  user-select: none;
  color: ${({ theme }) => theme.colors.grayMid};
  transform: translateY(-50%);
  transition: transform 0.25s, top 0.25s;
`;

export default Label;
