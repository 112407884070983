import * as React from "react";
import { ThemeProvider } from "styled-components";
import Helmet from "components/Helmet";

import NavBar from "components/NavBar";
import HeroSection from "components/HeroSection";
import AboutSection from "components/AboutSection";
import StatsSection from "components/StatsSection";
import ReviewsSection from "components/ReviewsSection";
import ContactSection from "components/ContactSection";
import Footer from "components/Footer";

import TopMenuContextProvider from "contexts/TopMenuContext";

import GlobalStyle from "styles/GlobalStyle";
import theme from "styles/theme";

const IndexPage = () => {
  return (
    <ThemeProvider theme={theme}>
      <TopMenuContextProvider>
        <Helmet />
        <GlobalStyle />
        <NavBar />
        <main>
          <HeroSection />
          <AboutSection />
          <StatsSection />
          <ReviewsSection />
          <ContactSection />
        </main>
        <Footer />
      </TopMenuContextProvider>
    </ThemeProvider>
  );
};

export default IndexPage;
