import styled from "styled-components";

const Links = styled.ul`
  all: unset;
  display: flex;
  flex-direction: column;
  margin-top: 48px;
  text-align: center;
  list-style: none;

  ${({ theme }) => theme.media.min.laptop`
    flex-direction: row;
    margin-top: 0;
  `}
`;

export default Links;
